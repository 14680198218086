// 学段控制学制的字段时候的年级
const GRADE = {
  KINDERGARTEN_GRADUATE: 10,
  ___KINDERGARTEN_GRADUATE: '幼儿园毕业',
  KINDERGARTEN_PRIMARY: 11,
  ___KINDERGARTEN_PRIMARY: '幼儿园小班',
  KINDERGARTEN_MIDDLE: 12,
  ___KINDERGARTEN_MIDDLE: '幼儿园中班',
  KINDERGARTEN_TOP: 13,
  ___KINDERGARTEN_TOP: '幼儿园大班',
  PRESCHOOL_GRADUATE: 20,
  ___PRESCHOOL_GRADUATE: '学前班毕业',
  PRESCHOOL_ONE: 21,
  ___PRESCHOOL_ONE: '学前班',
  PRIMARY_GRADUATE: 30,
  ___PRIMARY_GRADUATE: '小学毕业',
  PRIMARY_ONE: 31,
  ___PRIMARY_ONE: '小学一年级',
  PRIMARY_TWO: 32,
  ___PRIMARY_TWO: '小学二年级',
  PRIMARY_THREE: 33,
  ___PRIMARY_THREE: '小学三年级',
  PRIMARY_FOUR: 34,
  ___PRIMARY_FOUR: '小学四年级',
  PRIMARY_FIVE: 35,
  ___PRIMARY_FIVE: '小学五年级',
  PRIMARY_SIX: 36,
  ___PRIMARY_SIX: '小学六年级',
  MIDDLE_GRADUATE: 40,
  ___MIDDLE_GRADUATE: '初中毕业',
  MIDDLE_ONE: 41,
  ___MIDDLE_ONE: '初中一年级',
  MIDDLE_TWO: 42,
  ___MIDDLE_TWO: '初中二年级',
  MIDDLE_THREE: 43,
  ___MIDDLE_THREE: '初中三年级',
  HIGH_GRADUATE: 50,
  ___HIGH_GRADUATE: '高中毕业',
  HIGH_ONE: 51,
  ___HIGH_ONE: '高中一年级',
  HIGH_TWO: 52,
  ___HIGH_TWO: '高中二年级',
  HIGH_THREE: 53,
  ___HIGH_THREE: '高中三年级',
  TEACH_GRADUATE: 60,
  ___TEACH_GRADUATE: '职业教育毕业',
  TEACH_ONE: 61,
  ___TEACH_ONE: '职业教育一年级',
  TEACH_TWO: 62,
  ___TEACH_TWO: '职业教育二年级',
  TEACH_THREE: 63,
  ___TEACH_THREE: '职业教育三年级',
  VOCATION_GRADUATE: 70,
  ___VOCATION_GRADUATE: '中职毕业',
  VOCATION_ONE: 71,
  ___VOCATION_ONE: '中职一年级',
  VOCATION_TWO: 72,
  ___VOCATION_TWO: '中职二年级',
  VOCATION_THREE: 73,
  ___VOCATION_THREE: '中职三年级',
};
// 学制
const EDU_SYSTEM = _.get(window.CONST_DICT, 'departments.edu_system', {});
// 学段
const EDU_STAGE = _.get(window.CONST_DICT, 'departments.edu_stage', {});

const EDU_SYSTEM_STAGE_GRADE_RELATION = {

  // 学段(edu_stage)控制学制的(edu_system)信息
  [`SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_KINDERGARTEN}`]: [EDU_SYSTEM.EDU_SYSTEM_KINDERGARTEN],
  [`SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_PRESCHOOL}`]: [EDU_SYSTEM.EDU_SYSTEM_PRESCHOOL],
  [`SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_PRIMARY}`]: [EDU_SYSTEM.EDU_SYSTEM_PRIMARY, EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS, EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS, EDU_SYSTEM.EDU_SYSTEM_PRIMARY_TEACHING, EDU_SYSTEM.EDU_SYSTEM_PRIMARY_FIVE_YEARS, EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS_5_4, EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_5_4_3],
  [`SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_MIDDLE}`]: [EDU_SYSTEM.EDU_SYSTEM_JUNIOR_MIDDLE, EDU_SYSTEM.EDU_SYSTEM_COMPLETE_MIDDLE, EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS, EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS, EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS_5_4, EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_5_4_3],
  [`SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_HIGH}`]: [EDU_SYSTEM.EDU_SYSTEM_HIGH, EDU_SYSTEM.EDU_SYSTEM_COMPLETE_MIDDLE, EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS, EDU_SYSTEM.EDU_SYSTEM_HIGH_CAREER, EDU_SYSTEM.EDU_SYSTEM_HIGH_OTHER, EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_5_4_3],
  [`SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_CAREER_TEACHING}`]: [EDU_SYSTEM.EDU_SYSTEM_CAREER_TEACHING],
  [`SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_MEDIUM_CAREER}`]: [EDU_SYSTEM.EDU_SYSTEM_MEDIUM_TECHNOLOGY, EDU_SYSTEM.EDU_SYSTEM_MEDIUM_TEACHER],

  // 学制(edu_system)控制学段的学段(edu_stage)信息
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_KINDERGARTEN}`]: [EDU_STAGE.EDU_STAGE_KINDERGARTEN],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRESCHOOL}`]: [EDU_STAGE.EDU_STAGE_PRESCHOOL],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRIMARY}`]: [EDU_STAGE.EDU_STAGE_PRIMARY],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE, EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRIMARY_TEACHING}`]: [EDU_STAGE.EDU_STAGE_PRIMARY],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRIMARY_FIVE_YEARS}`]: [EDU_STAGE.EDU_STAGE_PRIMARY],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS_5_4}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_5_4_3}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE, EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_JUNIOR_MIDDLE}`]: [EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_COMPLETE_MIDDLE}`]: [EDU_STAGE.EDU_STAGE_MIDDLE, EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_MIDDLE_4}`]: [EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_HIGH}`]: [EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_HIGH_CAREER}`]: [EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_HIGH_OTHER}`]: [EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_CAREER_TEACHING}`]: [EDU_STAGE.EDU_STAGE_CAREER_TEACHING],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_MEDIUM_TECHNOLOGY}`]: [EDU_STAGE.EDU_STAGE_MEDIUM_CAREER],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_MEDIUM_TEACHER}`]: [EDU_STAGE.EDU_STAGE_MEDIUM_CAREER],

  // 学制(edu_system)控制学段的学段(edu_stage)信息
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_KINDERGARTEN}`]: [EDU_STAGE.EDU_STAGE_KINDERGARTEN],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRESCHOOL}`]: [EDU_STAGE.EDU_STAGE_PRESCHOOL],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRIMARY}`]: [EDU_STAGE.EDU_STAGE_PRIMARY],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE, EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRIMARY_TEACHING}`]: [EDU_STAGE.EDU_STAGE_PRIMARY],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_PRIMARY_FIVE_YEARS}`]: [EDU_STAGE.EDU_STAGE_PRIMARY],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_NINE_YEARS_5_4}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_5_4_3}`]: [EDU_STAGE.EDU_STAGE_PRIMARY, EDU_STAGE.EDU_STAGE_MIDDLE, EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_JUNIOR_MIDDLE}`]: [EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_COMPLETE_MIDDLE}`]: [EDU_STAGE.EDU_STAGE_MIDDLE, EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_MIDDLE_4}`]: [EDU_STAGE.EDU_STAGE_MIDDLE],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_HIGH}`]: [EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_HIGH_CAREER}`]: [EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_HIGH_OTHER}`]: [EDU_STAGE.EDU_STAGE_HIGH],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_CAREER_TEACHING}`]: [EDU_STAGE.EDU_STAGE_CAREER_TEACHING],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_MEDIUM_TECHNOLOGY}`]: [EDU_STAGE.EDU_STAGE_MEDIUM_CAREER],
  [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_MEDIUM_TEACHER}`]: [EDU_STAGE.EDU_STAGE_MEDIUM_CAREER],

  // 学段控制年级的
  [`GRADE_FOR_${EDU_STAGE.EDU_STAGE_KINDERGARTEN}`]: [GRADE.KINDERGARTEN_GRADUATE, GRADE.KINDERGARTEN_PRIMARY, GRADE.KINDERGARTEN_MIDDLE, GRADE.KINDERGARTEN_TOP],
  [`GRADE_FOR_${EDU_STAGE.EDU_STAGE_PRESCHOOL}`]: [GRADE.PRESCHOOL_GRADUATE, GRADE.PRESCHOOL_ONE],
  [`GRADE_FOR_${EDU_STAGE.EDU_STAGE_PRIMARY}`]: [GRADE.PRIMARY_GRADUATE, GRADE.PRIMARY_ONE, GRADE.PRIMARY_TWO, GRADE.PRIMARY_THREE, GRADE.PRIMARY_FOUR, GRADE.PRIMARY_FIVE, GRADE.PRIMARY_SIX],
  [`GRADE_FOR_${EDU_STAGE.EDU_STAGE_MIDDLE}`]: [GRADE.MIDDLE_GRADUATE, GRADE.MIDDLE_ONE, GRADE.MIDDLE_TWO, GRADE.MIDDLE_THREE],
  [`GRADE_FOR_${EDU_STAGE.EDU_STAGE_HIGH}`]: [GRADE.HIGH_GRADUATE, GRADE.HIGH_ONE, GRADE.HIGH_TWO, GRADE.HIGH_THREE],
  [`GRADE_FOR_${EDU_STAGE.EDU_STAGE_CAREER_TEACHING}`]: [GRADE.TEACH_GRADUATE, GRADE.TEACH_ONE, GRADE.TEACH_TWO, GRADE.TEACH_THREE],
  [`GRADE_FOR_${EDU_STAGE.EDU_STAGE_MEDIUM_CAREER}`]: [GRADE.VOCATION_GRADUATE, GRADE.VOCATION_ONE, GRADE.VOCATION_TWO, GRADE.VOCATION_THREE],
};

if ('true' === process.env.ADD_EDU_SYSTEM_TWELVE_YEARS_KDE) {
  _.assign(EDU_SYSTEM_STAGE_GRADE_RELATION, {
    [`STAGE_FOR_${EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_KDE}`]: [
      EDU_STAGE.EDU_STAGE_KINDERGARTEN,
      EDU_STAGE.EDU_STAGE_PRESCHOOL,
      EDU_STAGE.EDU_STAGE_PRIMARY,
      EDU_STAGE.EDU_STAGE_MIDDLE,
      EDU_STAGE.EDU_STAGE_HIGH,
    ],
  });

  const systemForKinderGarten = _.get(EDU_SYSTEM_STAGE_GRADE_RELATION, `SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_KINDERGARTEN}`);
  if (_.isArray(systemForKinderGarten)) {
    systemForKinderGarten.push(EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_KDE);
  }

  const systemForPreSchool = _.get(EDU_SYSTEM_STAGE_GRADE_RELATION, `SYSTEM_FOR_${EDU_STAGE.EDU_STAGE_PRESCHOOL}`);
  if (_.isArray(systemForPreSchool)) {
    systemForPreSchool.push(EDU_SYSTEM.EDU_SYSTEM_TWELVE_YEARS_KDE);
  }
}

const DICT = {
  EDU_SYSTEM_STAGE_GRADE_RELATION,
  EVENT: {
    CAS_AUTH_401: 'CAS_AUTH_401',
    CAS_JUMP_AUTH: 'CAS_JUMP_AUTH',
    CAS_JUMP_ADMIN_AUTH: 'CAS_JUMP_ADMIN_AUTH',
    CAS_LOGOUT: 'CAS_LOGOUT',
    CAS_CALLBACK: 'CAS_CALLBACK',
    WEBSITE_GET_USERINFO: 'WEBSITE_GET_USERINFO',
    WEBSITE_GET_USERINFO_FAIL: 'WEBSITE_GET_USERINFO_FAIL',
    WEBSITE_TOKEN_EMPTY: 'WEBSITE_TOKEN_EMPTY',
    RENDER_APP: 'RENDER_APP',
    CAS_LOGIN: 'CAS_LOGIN',
    WEBSITE_GET_USER_TYPE: 'WEBSITE_GET_USER_TYPE',
  },
  TOKEN_KEY: `${process.env.PROJ}_jwtToken_${['development', 'develop'].includes(process.env.NODE_ENV) ? 'dev' : 'prod'}_edu_v1`,
  SHOW_ANNOUNCEMENT_TAG: 'SHOW_ANNOUNCEMENT_TAG',
  API_DOMAIN_WITH_PREFIX: `${process.env.API_DOMAIN_WITH_PREFIX}`.replace(/\/$/, ''),
  METHOD: {
    GET: 0,
    POST: 1,
  },
};

window.DICT = DICT;
export { DICT };
