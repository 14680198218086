import { DICT } from '../utils/dict';

const BASE_PATH = `${DICT.API_DOMAIN_WITH_PREFIX}/user_message`;

export default class Component extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    window.globalMessageReact = this;
  }

  componentDidMount = () => {
    if (!_.includes(location.hash, 'model=msg')) {
      if ($.cookie(DICT.TOKEN_KEY)) {
        this.getUnread();
      }
    }
    else {
      // do nothing
    }
  }

  getUnread = () => {
    const filter = [];
    filter.push(['status', '=', _.get(CONST_DICT, 'user_messages.status.STATUS_UNREAD')]);
    $.ajax({
      url: BASE_PATH,
      type: 'get',
      data: {
        page: 1,
        take: _.get(this.state, 'info.per_page'),
        filter: JSON.stringify(filter),
      },
      success: (res) => {
        if (0 < _.get(res, 'data.data.length')) {
          $('.msg-badge').css({
            display: 'inline-block',
          });
          antd.Modal.confirm({
            title: '未读消息提醒',
            content: (<div>
              你当前有{_.get(res, 'data.data.length')}条未读消息，请前往消息中心
            </div>),
            cancelText: '关闭',
            okText: '前往查看',
            onOk: () => {
              window.location.href = `/website/user#model=msg&key=${_.get(CONST_DICT, 'user_messages.status.STATUS_UNREAD')}`;
            },
            onCancel: () => {},
          });
        }
        else {
          $('.msg-badge').hide();
        }
      },
    });
  }

  render() {
    return (<span>global-message</span>);
  }
}
const hiddenElem = $('<span class="ant-hide" style="display:none" />');
$('body').append(hiddenElem);

ReactDOM.render(<Component />, hiddenElem[0]);
